<template>
  <div class="text-center">
    <div class="title-bar">Select Vehicle</div>
    <div class="d-flex justify-center">
      <v-checkbox v-model="showInactive" label="Show Inactive" />
    </div>
    <div class="d-flex flex-wrap justify-center align-content-center">
      <!-- <router-link v-for="vehicle in filteredVehicles" :key="vehicle.id" :to="{ name: 'Fuel Entries', params: { vehicleId: vehicle.id } }" >
        <v-card class="ma-2 vehicle" >
          <div v-if="!vehicle.isActive" class="inactive">
            <div>-INACTIVE-</div>
          </div>
          <v-icon>mdi-car-side</v-icon>
          <div>{{vehicle.name}}</div>
        </v-card>   
      </router-link> -->

      <v-card class="ma-2 vehicle" v-for="vehicle in filteredVehicles" :key="vehicle.id" :to="{ name: 'Fuel Entries', params: { vehicleId: vehicle.id } }">
        <div v-if="!vehicle.isActive" class="inactive">
          <div>-INACTIVE-</div>
        </div>
        <v-icon>mdi-car-side</v-icon>
        <div>{{vehicle.name}}</div>
      </v-card>   
    </div>
  </div>
</template>

<script>
import vehicleService from '@/services/vehicle.service.js';

export default {
  name: "VehiclesList",

  props: [
      
  ],
  components: {
  }, 

  data: () => ({
    vehiclesList: [],
    showInactive: false,
  }),

  computed: {
    filteredVehicles() {
      return this.vehiclesList.filter(v => this.showInactive || v.isActive);
    }      
  },
  methods: {
    async getVehicles() {
      const result = await vehicleService.getVehicles();
      this.vehiclesList = result.data;
    },
    selectVehicle(vehicle) {
      this.$emit("vehicleSelected", vehicle);
    },    
  }, 
  async mounted() {
    this.getVehicles();
  }

}
</script>


<style scoped>
.info-button {
  position: absolute;
  top: 8px;
  right: 10px;
}
.vehicle {
    position:relative;
    flex-basis: 225px;
    flex-grow: 1;
    max-width: 300px;  
    min-width: 150px;  
    height: 130px;
    text-align: center
}

.vehicle:not(.disabled):hover {
    cursor: pointer;
    background-color: #ddd;
}

.vehicle:not(.disabled):active,
:not(.disabled):focus {
    cursor: pointer;
    background-color: rgb(206, 205, 248);
}


.vehicle.disabled {
    opacity: .6;
}
.vehicle.disabled:hover {
    cursor: not-allowed;
}

.vehicle .v-icon:not(.info-button) {
    font-size: 110px;
    width: 110px;
    height: 100px;
    display: inline-block;
    line-height: 95px;
}

.vehicle .label {
    font-size: 22px;
}

.inactive {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #888;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgba(0,0,0,.10);
  height: 100%;
  align-content: center;
  display: flex;
}
.inactive div {
  height: min-content;
  width: 100%;
  font-size: 22px;
}

@media screen and (max-width: 600px) {
    .vehicle {
        height: 138px;
    }

    .vehicle .v-icon {
        font-size: 100px;
        height: 100px;
        line-height: 112px;
    }
    
    .vehicle .label {
        font-size: 22px;
    }   
    
}
</style>